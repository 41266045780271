<!--  -->
<template>
  <div>
    <Header />
    <Sidebar />
    <div class="main">
      <el-row :gutter="20">
        <el-col :span="10"
          ><div class="grid-content bg-purple top">
            <img
              src="@/assets/icon/路径 6@2x.png"
              style="weight: 32px; height: 32px"
              alt=""
            />
            <p class="title">您的订单已支付成功，我们将尽快为您安排商务对接</p>
          </div></el-col
        >
      </el-row>
      <el-row :gutter="20">
        <el-col
          :span="10"
          :offset="1"
          style="padding-left: 5px; padding-right: 10px"
        >
          <div class="grid-content bg-purple text">
            <ul>
              <li>
                <p>订单号：</p>
                <span style="margin-left: 13px">{{
                  this.$route.query.orderNumber
                }}</span>
              </li>
              <li>
                <p>商品名称：</p>
                <span>{{ name }}</span>
              </li>
              <li>
                <p>订单金额：</p>
                <span>¥{{ shop.actualTotal }}</span>
              </li>
              <li>
                <p>支付方式：</p>
                <span>{{ shop.payTypeLabel }}</span>
              </li>
              <li>
                <p>推荐人：</p>
                <span>{{ shop.referrer }}</span>
              </li>
              <li>
                <p>备注：</p>
                <span v-if="shop.takeRemark">{{ shop.takeRemark }}</span>
                <span v-else>无</span>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :span="10"
          :offset="1"
          style="padding-left: 5px; padding-right: 10px; margin-top: 30px"
        >
          <router-link
            :to="{
              name: 'order',
              query: { username, orderNumber: this.$route.query.orderNumber },
            }"
          >
            <button
              style="
                width: 136px;
                height: 36px;
                background: #b60005;
                border-radius: 2px 2px 2px 2px;
                color: #ffffff;
              "
            >
              查看订单
            </button>
          </router-link>
          <router-link to="/">
            <button
              style="
                width: 136px;
                height: 36px;
                border-radius: 2px 2px 2px 2px;
                border: 1px solid #b60005;
                color: #b60005;
                background: #ffffff;
                margin-left: 35px;
              "
            >
              返回首页
            </button>
          </router-link>
        </el-col>
      </el-row>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
import { getorderDetail } from "@/api/all.js";
export default {
  components: { Header, Footer, Sidebar },
  data() {
    return {
      username: "",
      shop: {},
      name: null,
      price: null,
    };
  },
  created() {
    this.username = localStorage.getItem("username");
    // viewState({ orderNumber: this.$route.query.orderNumber }).then((res) => {
    // })
    getorderDetail({ orderNumber: this.$route.query.orderNumber }).then(
      (res) => {
        console.log(res.data);
        this.shop = res.data;
        this.name = res.data.orderItemDtos[0].prodName;
        this.price = res.data.orderItemDtos[0].productTotalAmount;
      }
    );
  },
  methods: {},
};
</script>

<style scoped>
.text > ul > li > p {
  color: #666666;
  font-size: 14px;
}
.text > ul > li {
  margin: 14px 0;
  display: flex;
}
.top > p {
  font-size: 18px;
  font-weight: bold;
  color: #141414;
}
.top > img {
  margin-right: 16px;
}
.top {
  display: flex;
  align-items: center;
  padding-top: 62px;
}
.main {
  min-height: 666px;
  padding-top: 80px;
  padding-bottom: 60px;
  border-bottom: 1px solid #eee;
  width: 1220px;
  margin: 0 auto;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
}
</style>
